
import {
  defineAsyncComponent,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
} from "vue";
import { gpf } from "@/utils/global-functions";
import { useRoute } from "vue-router";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const servicesV1Public = async () => await import(`@/services/v1/public/index`);

export default defineComponent({
  name: "ModalForgotPassword",
  components: {
    ModalTemplate,
  },
  setup() {
    // START --- for forgot password ------------------------------------------------------------------------
    const form = reactive({
      email: "",
    });

    const emptyForm = () => {
      form.email = "";
    };

    const requestLupaPassword = async () => {
      const { forgotPassword } = await servicesV1Public();
      gpf.gLoading.show();

      const payload = new URLSearchParams();
      payload.append("email", form.email);

      try {
        const hit = await forgotPassword(payload);
        // console.log("hit ", hit);

        gpf.hideModal("#modal-lupa-password");
        gpf.handleSdsV2(hit?.diagnostic?.message);

        // make empty form email
        form.email = "";

        gpf.gLoading.hide();
      } catch (e: any) {
        const err = e.response;
        console.log("err response ", err);

        gpf.gLoading.hide();
        gpf.handleEds(e);
      }
    };

    const onCancelClick = () => {
      form.email = "";
      gpf.hideModal("#modal-lupa-password");
    };

    // END --- for forgot password --------------------------------------------------------------------------

    const route = useRoute();

    onMounted(() => {
      if (route.name === "login") {
        cash("body")
          .removeClass("app")
          .removeClass("error-page")
          .addClass("login");
      }
    });

    onUnmounted(() => {
      form.email = "";
    });

    return {
      form,
      requestLupaPassword,
      gpf,
      onCancelClick,
    };
  },
});
